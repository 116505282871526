import React from "react";
import styled from "@emotion/styled";

import Section from "@components/Section";
import SEO from "@components/SEO";
import Layout from "@components/Layout";
import Subscription from "@components/Subscription";

import AuthorHero from "../sections/author/Author.Hero";

import { Template } from "@types";

const ArticlesPage: Template = ({ location, pageContext }) => {
  const author = pageContext.additionalContext.author;

  return (
    <Layout>
      <SEO
        pathname={location.pathname}
        title={`${author.name}: About me`}
        description={author.bio}
      />
      <Section narrow>
        <AuthorHero author={author} />
        {pageContext.mailchimp && <Subscription />}
      </Section>
      <AuthorsGradient />
    </Layout>
  );
}

export default ArticlesPage;

const AuthorsGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 590px;
  z-index: 0;
  pointer-events: none;
  background: ${p => p.theme.colors.gradient};
  transition: ${p => p.theme.colorModeTransition};
`;